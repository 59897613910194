import './EasySoftwareTimeline.scss'

import React, { FunctionComponent } from 'react'

export interface TimelineItemType {
  displayText: string
  timelineItemContent: string
}

export interface EasySoftwareTimelineType {
  displayText: string
  timelineItems: {
    contentItems: [TimelineItemType]
  }
}

const rootClass = `timeline`

const EasySoftwareTimeline: FunctionComponent<EasySoftwareTimelineType> = (
  props
) => (
  <div className={rootClass}>
    {(() => {
      if (props.displayText && props.displayText.length) {
        return <h2 className={`${rootClass}-title`}>{props.displayText}</h2>
      }
    })()}

    <span className={`${rootClass}-line`}></span>

    <div className={`${rootClass}-items`}>
      {props.timelineItems &&
        props.timelineItems.contentItems &&
        props.timelineItems.contentItems.map((item, idx) => (
          <div className={`${rootClass}-item`} key={idx}>
            <span className={`${rootClass}-item-placeholder`}></span>
            <span className={`${rootClass}-item-marker`}></span>
            <div className={`${rootClass}-item-content`}>
              <h3 className={`${rootClass}-year`}>{item.displayText}</h3>
              <div className={`${rootClass}-text`}>
                {item.timelineItemContent}
              </div>
            </div>
          </div>
        ))}
    </div>
  </div>
)

export default EasySoftwareTimeline
